.smartworkingCell {
  cursor: pointer;
  width: 210px; 
  height: 27px;
  }

  .filled{
  background: rgba(139, 195, 74, 0.72);
  }

  .houseIcon{
  color:#000000c9;
  }