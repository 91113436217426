.logo_Qilab{
  max-height: 60px;
}

.logo_Quid {
  padding: 0px 10px;
  max-height: 60px;
}

.logo_Quin {
  margin-top: 0%;
  padding: 6px;
  height: 6vh;
  width: 11vw;
}

.custom-header {
  height: 78px;
  /* -webkit-box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3); */
  /* -moz-box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3); */
  box-shadow: 1px 1px 12px rgba(0, 0, 0, 0.2);
}

.custom-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 86vh;
}

.content-grid {
  margin-top: 2%;
  min-height: 81%;
  /* width: 100%; */
}

.buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.label {
  font-family: 'Work Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.03em;
  color: #323232;
  padding-bottom: 1.3px;
  /* height: 36px; */
  /* box-shadow: 0 3px 5px rgb(57 63 72 / 30%); */
}

.ms-05{
  margin-left: 0.5%;
}

.w-300{
  width: 300px;
}