

.form-select{
  font-family: 'Work Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.03em;
  color: #323232;
  border: none;
  border-bottom: 1.6px solid transparent;
  border-radius: 0px;
  width: 130px;
  height: 36px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.form-select:hover{
  border-bottom: 1.6px solid #CA5018;
  box-shadow: none;
}

.form-select:focus{
  border-bottom: 1.6px solid transparent;
  box-shadow: none;
}

/* 
.form-select-group{
  max-width: 130px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  box-shadow: 1px 1px 12px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  border: 1px solid #323232;
} */

.form-select-option{
  font-family: 'Work Sans', sans-serif;
  font-style: normal;
  font-weight: 400 !important;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.03em;
  color: #323232;
  width: 130px !important;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
}

.form-select-option:hover{
  font-weight: 500;
  background-color: #ECF1F4;
  
}

/* option {
  font-weight: normal;
  display: block;
  white-space: nowrap;
  min-height: 1.2em;
  padding: 0px 4px 2px;

  font-family: 'Work Sans';
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px!important;
  line-height: 100px!important;
  letter-spacing: 0.03em!important;
  color: #323232;
  width: 130px !important;
  cursor: pointer;
  white-space: nowrap!important;
  overflow: hidden!important;
  text-overflow: ellipsis!important;
  box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3)!important;
} */
/* 
::selection {
  color: #fff;
  background: #1890ff;
} */

/* select:-internal-list-box option:hover {
  background-color: red !important;
}

select:-internal-list-box option {
  line-height: 100px !important;
}  */