.ag-theme-balham {
  --ag-header-foreground-color: #000000a3;
  --ag-header-background-color: #ECF1F4;
  --ag-header-cell-hover-background-color: rgb(80, 40, 140);
  --ag-header-cell-moving-background-color: rgb(80, 40, 140);
  --ag-row-border-color: #d6e0e6;  
  --ag-border-color: #d6e0e6;  
  --ag-font-family: 'Satoshi';
  box-shadow: 1px 1px 12px rgba(0, 0, 0, 0.2);
  /* box-shadow: 6px 6px 50px rgba(0, 0, 0, 0.1); */
  border-radius: 10px; 

}

/* modifica label nell'header */
.ag-theme-balham .ag-header-cell, .ag-theme-balham .ag-header-group-cell {
  /* font-size: 12px;
  font-family: 'Work Sans', sans-serif; */
  /* font-family: var(--ag-font-family); */
  font-style: normal;
  font-weight: 500;
}

/* modifica testi nelle celle */
.ag-theme-balham .ag-cell  {
  /* font-family: 'Work Sans', sans-serif; */
  /* font-family: var(--ag-font-family); */
  /* font-style: normal;
  font-weight: 400; */
  color: #323232;
}

.ag-theme-balham .ag-header-expand-icon {
  color: white;
}

.ag-theme-balham .ag-root-wrapper {
  border-radius: 10px;
}

.show-cell {
  background: blue;
  color: white;
  border-left: 1px solid rgb(255, 0, 0) !important;
  border-right: 1px solid rgb(255, 0, 0) !important;
  border-bottom: 1px solid rgb(255, 0, 0) !important;
}

/* per bordo sezione sx */
.ag-theme-balham .ag-pinned-left-header {
  border-right: solid 1px;
  border-right-color: var(--ag-border-color, #d6e0e6);
}