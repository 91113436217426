.btn-custom{
  background-color: #EA682F;
  padding: 6px 12px;
  color: #FAFAFA;
  border: 1.8px solid #EA682F     ;
  font-family: 'Work Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.05em;
}

.btn-custom:hover{
  background-color: #FAFAFA;
  color: #EA682F;
  border: 1.8px solid #EA682F;
}

.btn-custom:focus{
  border: 1.8px solid #863310;
  background-color: #EA682F;
  box-shadow: inset 0px 0px 6px #8f4d316b;
  color: #FAFAFA;
}

.btn-custom:active{
  background-color: #EA682F !important;
  color: #FAFAFA !important;
}