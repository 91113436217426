.form-check-input:checked {
    width: 30px;
    height: 15px;
    background-color: #EA682F;
    border-color: #EA682F;
    box-shadow: inset 1px -1px 2px rgba(0, 0, 0, 0.25);
    border-radius: 92.5px;
}

.form-check-input {
    width: 30px;
    height: 15px;
    background-color: #B6B6B6;
    border-color: #B6B6B6;
    box-shadow: inset -1px -1px 2px rgba(0, 0, 0, 0.25);
    border-radius: 100px;
    position: relative;
    cursor: pointer;
}

.form-check-input::before{
    position: absolute;
    content: "";
    height: 15px;
    width: 15px;    
    top: -1px;
    left: -1px;
    background-color: white;
    background: linear-gradient(135deg, #FFFFFF 0%, #EBEBEB 100%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 4px 4px 24px rgba(0, 0, 0, 0.25);

    border-radius: 50%;
    /* -webkit-transition: .4s; 
     transition: .4s; */
    /* transition: left .4s ease-in-out; */
} 


/* pallino non bg ma span */
.form-switch .form-check-input:checked {
    background-image: none;
}

.form-switch .form-check-input {
    width: 2em;
    margin-left: -2.5em;
    border-radius: 2em;
    background-image: none;
    -webkit-transition: .4s;
    transition: .4s;
    /* transition: left .4s ease-in-out; */
}

.form-check-label{
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.03em;
    color: #323232;
}

.form-check-input:checked::before {
    /* -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px); */
    left: 15px;
}

.form-check-input:active {
    box-shadow: none;
}